import {cloneDeep} from 'lodash'
const state = {
  menus: {}
}
const mutations = {
  SET_MenuInfo(state, payload) {
    state.menus = payload;
  },
}

const actions = {
  getMenuInfo({ commit },menuRoutes) {
    var menus = {};
    var routes = cloneDeep(menuRoutes);
    for (var i = 0; i < routes.length; i++) {
      menus[routes[i].meta.id] = markMenuInfo(routes[i])
    }
    commit('SET_MenuInfo', menus)
  },
}

//制作左菜单信息
function markMenuInfo(menu) {
  if (!menu.children) {
    return;
  }
  var mainMeta = menu.meta;
  var menuInfo = deepAddMeta(mainMeta, menu.children);
  if(mainMeta.breadcrumbShow){
    menuInfo = deepAddBread(menu.children);
  }
  return menuInfo;

}

//深度添加meta
function deepAddMeta(data, arr) {
  var newArr = [];
  for (let i = 0; i < arr.length; i++) {
    Object.assign(arr[i].meta, data)
    newArr.push(arr[i]);
    if (arr[i].children) {
      newArr[i].children = deepAddMeta(data, newArr[i].children)
    }
  }
  return newArr;
}

//深度添加面包屑数据
function deepAddBread(arr, breadData) {
  var newArr = [];
  for (let i = 0; i < arr.length; i++) {
    var item = arr[i];
    if(!item.meta){
         return;
    }
    var breadDatas = [];
    if (breadData) {
      breadDatas = [...breadData];
    }
    
    var tempData = {};
    if (item.meta.breadcrumbTitle) {
      tempData.name = item.meta.breadcrumbTitle
    } else if (item.meta.title) {
      tempData.name = item.meta.title
    }

    if (breadDatas.length === 0) {
      tempData.url = item.path
    } else {
      tempData.url = breadDatas[breadDatas.length - 1].url + '/' + item.path
    }

    if(item.path !== ''){
      breadDatas.push(tempData);
    }

    
    Object.assign(arr[i].meta, { breadcrumbList: breadDatas })
    newArr.push(arr[i]);

    if (arr[i].children) {
      newArr[i].children = deepAddBread(newArr[i].children, breadDatas)
    }

  }

  return newArr;
}

export default {
  namespaced: true,
  state,
  mutations,
  actions
}
