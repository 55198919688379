//1.导入vue和router包
import Vue from "vue";
import VueRouter from "vue-router";
import store from "@/store";
import { constantRoutes, asyncMenuRoutes, asyncRoutes } from "./modules";
import { cloneDeep, flattenDeep } from "lodash";
import { GetQueryValue } from "@/utils/public2";
//2.调用Vue.use()函数，把 VueRouter 安装为 Vue 的插件
Vue.use(VueRouter);

//添加以下代码
const originalPush = VueRouter.prototype.push;
VueRouter.prototype.push = function push(location) {
  return originalPush.call(this, location).catch((err) => err);
};

//处理左菜单
function handleMenuRoutes() {
  //通过权限过滤左菜单相关路由

  //制作菜单和面包屑数据
  store.dispatch("menu/getMenuInfo", asyncMenuRoutes);

  //将制作好的菜单信息路由整理成路由对象
  return flattenDeep(Object.values(cloneDeep(store.state.menu.menus)));
}

const newMenuRouters = handleMenuRoutes();
//3.创建路由的示例对象
const router = new VueRouter({
  routes: [...asyncRoutes, ...newMenuRouters, ...constantRoutes,],
});

router.beforeEach((to, from, next) => {
  // app
  if (GetQueryValue("DeviceId")) {
      document.cookie = "DeviceId=" + GetQueryValue("DeviceId");
      document.cookie = "UserId=" + GetQueryValue("UserId");
      document.cookie = "DeviceCode=" + GetQueryValue("DeviceCode");
      document.cookie = "DeviceType=" + GetQueryValue("DeviceType");
      document.cookie = "ssotoken=" + GetQueryValue("ssotoken");
      // 获取当前 URL
      const currentUrl = new URL(window.location.href);
      // // 从 URLSearchParams 对象中删除指定的查询参数
      currentUrl.searchParams.delete("DeviceId");
      currentUrl.searchParams.delete("UserId");
      currentUrl.searchParams.delete("DeviceCode");
      currentUrl.searchParams.delete("DeviceType");
      currentUrl.searchParams.delete("ssotoken");
      // 修改浏览器历史状态，替换当前 URL，但不会刷新页面
      window.history.replaceState({}, document.title, currentUrl.toString());
      next()
  }
  else if (GetQueryValue("ssotoken")) {
      document.cookie = "ssotoken=" + GetQueryValue("ssotoken");
      // 获取当前 URL
      const currentUrl = new URL(window.location.href);
      // 从 URLSearchParams 对象中删除指定的查询参数
      currentUrl.searchParams.delete("ssotoken");
      currentUrl.searchParams.delete("state");
      // 修改浏览器历史状态，替换当前 URL，但不会刷新页面
      window.history.replaceState({}, document.title, currentUrl.toString());
      next()
  }
  else{
      next()
  }

});



//4.向外共享路由示例对象
export default router;
