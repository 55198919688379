import { request } from "../request.js"

//发布编辑
export default {

    // 用户列表
    reqFarmerList: ({ pageSize, index, Area, Status, AuthType }) => request({
        url: `/Customers?pageSize=${pageSize}&index=${index}&Area=${Area.join(" ")}&Status=${Status}&AuthType=${AuthType}`,
        method: 'GET',
    }),

    reqUpdateStatus: (data) => request({
        url: '/Customers',
        method: 'PATCH',
        data
    })
}
