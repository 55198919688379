
import businessColumnHttp from './modules/businessColumn'
import businessReleaseHttp from './modules/businessRelease'
import businessReleaseEditHttp from './modules/businessReleaseEdit'
import businessFormHttp from './modules/businessForm'
import businessApplyHttp from './modules/businessApply'

import mallClassifyHttp from './modules/mallClassify'
import mallMsgHttp from './modules/mallMsg'
import mallOrderHttp from './modules/mallOrder'

import productClassifyHttp from './modules/productClassify'
import productAuditHttp from './modules/productAudit'
import productMsgHttp from './modules/productMsg'
import productOrderHttp from './modules/productOrder'

import farmerListHttp from './modules/farmerList'


export const farmerList = farmerListHttp

export const businessColumn = businessColumnHttp
export const businessRelease = businessReleaseHttp
export const businessReleaseEdit = businessReleaseEditHttp
export const businessForm = businessFormHttp
export const businessApply = businessApplyHttp

export const mallClassify = mallClassifyHttp
export const mallMsg = mallMsgHttp
export const mallOrder = mallOrderHttp

export const productClassify = productClassifyHttp
export const productAudit = productAuditHttp
export const productMsg = productMsgHttp
export const productOrder = productOrderHttp




