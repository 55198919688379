import { businessRelease } from "@/api/index"

const actions = {
    //获取发布管理右边列表
    async getArticlesList(context, obj) {
        let res = await businessRelease.reqArticlesList(obj)
        if(res.Data){
            res.Data.forEach((item, index) => {
                res.Data[index].check = false
            })
        }
        context.state.total = res.TotalItemCount
        context.state.articlesList = res.Data
    }
}

const mutations = {}


const state = {
    articlesList: [],
    total:0
}

const getters = {

}





export default {
    namespaced: true,
    state,
    mutations,
    actions,
    getters
}

