import { request } from "../request.js"

//农资商城管理分类
export default {
    // 获取分类列表
    reqOrderList: ({ pageSize, index, PublisherType, Status, Type, keyWord }) => request({
        url: `/tradeorder?pageSize=${pageSize}&index=${index}&PublisherType=${PublisherType}&Status=${Status}&Type=${Type}&keyWord=${keyWord}`,
        method: 'GET',
    }),


    // 编辑
    reqUpdataMsg: (data) => request({
        url: `/tradeorder`,
        method: 'PATCH',
        data
    }),

    // 确定意向书
    reqIntentStatus: (data) => request({
        url: `/tradeorder/intent`,
        method: 'PATCH',
        data
    }),

    // 订单状态
    reqOrderStatus: (data) => request({
        url: `/tradeorder/status`,
        method: 'PATCH',
        data
    }),





}