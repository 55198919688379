import { request } from "../request.js"

//农资商城管理分类
export default {
    // 获取分类列表
    reqCategoryList: ({ pageSize, index, parentId, keyWord }) => request({
        url: `/categories?pageSize=${pageSize}&index=${index}&parentId=${parentId}&keyWord=${keyWord}`,
        method: 'GET',
    }),

    reqUpdateStatus: (data) => request({
        url: "/categories/status",
        method: 'PATCH',
        data
    }),

    reqAddClassify: (data) => request({
        url: "/categories",
        method: 'POST',
        data
    }),

    reqUpdataClassify: (data) => request({
        url: "/categories",
        method: 'PATCH',
        data
    }),

}
