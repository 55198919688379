import 'core-js/stable'
// import 'regenerator-runtime/runtime'
import Vue from 'vue'
import App from './App.vue'
import router from '@/router/index.js'
import store from './store'
import "@/assets/icon/nong/iconfont.css"
import '@/assets/css/reset.css'
import '@/assets/css/theme.scss';
import '@/assets/css/public.css'
import '@/assets/css/backstagePublic.scss'
import '@/assets/css/reviseProgress.css'
import '@/assets/css/km.css'
import ElementUI from 'element-ui';
import * as echarts from 'echarts';
import tinymce from 'tinymce'
Vue.use(VueClipboard);

Vue.prototype.$tinymce = tinymce // 将全局tinymce对象指向给Vue作用域下
// Vue.use(VueTinymce)              // 安装vue的tinymce组件

import VueClipboard from 'vue-clipboard2';



Vue.config.productionTip = false
Vue.prototype.$echarts = echarts;

Vue.use(ElementUI);
new Vue({
  render: h => h(App),
  router,
  store,
}).$mount('#app')
