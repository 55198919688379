import { request } from "../request.js"

//发布编辑
export default {
    // 获取栏目管理左边目录 0获取禁用的，1获取可用的，-100获取删除了的，null获取非删除状态的
    reqChannelsDirectory: ({ status }) => request({
        url: `/channels/list?status=${status}`,
        method: 'GET',
    }),

    // 获取栏目管理右边列表
    reqChannelsList: ({ pageSize, index, parentCodes, status, searchText }) => request({
        url: `/channels?pageSize=${pageSize}&index=${index}&parentCodes=${parentCodes}&status=${status}&keyWord=${searchText}`,
        method: 'GET',
    }),

    //新增栏目
    reqAddColumn: (data) => request({
        url: `/channels`,
        method: 'POST',
        data
    }),

    //修改栏目
    reqUpdataColumn: (data) => request({
        url: `/channels`,
        method: 'PATCH',
        data
    }),

    //更新状态
    reqUpdataStatus: (data) => request({
        url: "/channels/status",
        method: 'PATCH',
        data
    }),

}
