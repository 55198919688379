import { request } from "../request.js"

//发布编辑
export default {
    // 获取申请管理列表
    reqApplyList: ({ pageSize, index, channelCode, status }) => request({
        url: `/eventLogs?pageSize=${pageSize}&index=${index}&channelCode=${channelCode}&status=${status}`,
        method: 'GET',
    }),

    //获取状态
    reqStatusList: () => request({
        url: `/eventLogs/status`,
        method: 'GET',
    }),

    //获取状态
    reqApplyItem: (id) => request({
        url: `/eventLogs/${id}`,
        method: 'GET',
    }),

    //修改审核
    reqChecked: (data) => request({
        url: `/eventLogs`,
        method: 'PATCH',
        data
    }),
}