import { request } from "../request.js"

//农资商城管理分类
export default {
    // 获取分类列表
    reqMsgList: ({ pageSize, index, PublisherType, tradeStatus, categoryId, type }) => request({
        url: `/trade?pageSize=${pageSize}&index=${index}&PublisherType=${PublisherType}&tradeStatus=${tradeStatus}&type=${type}&PublishStatus=100&categoryId=${categoryId}`,
        method: 'GET',
    }),
    // 获取分类列表
    reqCategoryList: ({ pageSize = "", index = "", parentId = "", keyWord = "", IsPaged = true }) => request({
        url: `/categories?pageSize=${pageSize}&index=${index}&parentId=${parentId}&keyWord=${keyWord}&IsPaged=${IsPaged}`,
        method: 'GET',
    }),
    // 更新商品状态
    reqUpdatePublishStatus: (data) => request({
        url: `/trade/tradeStatus`,
        method: 'PATCH',
        data
    }),
    // 官方发布
    reqAddMsg: (data) => request({
        url: `/trade`,
        method: 'POST',
        data
    }),

    // 编辑
    requpdataMsg: (data) => request({
        url: `/trade`,
        method: 'PATCH',
        data
    }),

    // 发起订单
    reqAddOrder: (data) => request({
        url: `/tradeorder`,
        method: 'POST',
        data
    }),


}
