// 判断数据类型
const getObjType = (obj) => {
  var toString = Object.prototype.toString;
  var map = {
    "[object Boolean]": "boolean",
    "[object Number]": "number",
    "[object String]": "string",
    "[object Function]": "function",
    "[object Array]": "array",
    "[object Date]": "date",
    "[object RegExp]": "regExp",
    "[object Undefined]": "undefined",
    "[object Null]": "null",
    "[object Object]": "object",
  };
  if (obj instanceof Element) {
    return "element";
  }
  return map[toString.call(obj)];
};

// 深度克隆
const deepClone = (data) => {
  var type = getObjType(data);
  var obj;
  if (type === "array") {
    obj = [];
  } else if (type === "object") {
    obj = {};
  } else {
    // 不再具有下一层次
    return data;
  }
  if (type === "array") {
    for (var i = 0, len = data.length; i < len; i++) {
      obj.push(deepClone(data[i]));
    }
  } else if (type === "object") {
    for (var key in data) {
      obj[key] = deepClone(data[key]);
    }
  }
  return obj;
};

//对象变成数组
const objToArr = (obj) => {
  var newArr = [];
  for (let key in obj) {
    newArr.push(obj[key]);
  }
  return newArr;
};

//平铺数组
const flatMenuRoutes = (Arr) => {
  //只要有一个元素有数组，那么循环继续
  while (Arr.some(Array.isArray)) {
    Arr = [].concat(...Arr);
  }

  return Arr;
};


const GetQueryValue = (queryName) => {
  console.log(location.href);
  const queryList = location.href.split('?')[1]?.split('&') || []
  const curQuery = queryList.find(item => item.includes(queryName)) // 或用 filter 方法
  const j = curQuery?.indexOf('=')
  return curQuery?.slice(j + 1).split('#/')[0]||''
};
// const GetQueryValue = (queryName) => {
//   const queryList = location.href.split('?')[1]?.split('&') || []
//   const curQuery = queryList.find(item => item.includes(queryName)) // 或用 filter 方法
//   const j = curQuery?.indexOf('=')
//   const k = curQuery?.indexOf('#/')
//   return curQuery?.slice(j + 1,k)||''
// };

// 获取cookie
const getCookie = (name) => {
  var arr,
    reg = new RegExp("(^| )" + name + "=([^;]*)(;|$)");
  if ((arr = document.cookie.match(reg))) return unescape(arr[2]);
  else return null;
};

export {
  getObjType,
  deepClone,
  objToArr,
  flatMenuRoutes,
  GetQueryValue,
  getCookie,
};
