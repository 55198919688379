import {menuRoutes} from './menu'
import {cloneDeep} from 'lodash'

// 固定路由（默认路由）
const constantRoutes = [
    {
        path: '/',
        redirect: '/user/farmerList'
    },
    {
        path: '/:all(.*)*',
        name: 'NotFound',
        component: () => import('@/views/error-page'),
        meta: {
            title: '找不到页面',
        },
    },
]


// 动态路由（导航栏路由）
const asyncMenuRoutes = cloneDeep(menuRoutes)

// 动态路由（异步路由）
const asyncRoutes = [
    
]


export{
    constantRoutes,
    asyncMenuRoutes,
    asyncRoutes
}
