import { request } from "../request.js"

//发布编辑
export default {
    // 获取栏目管理左边目录
    reqFormList: ({ pageSize, index }) => request({
        url: `/events?pageSize=${pageSize}&index=${index}`,
        method: 'GET',
    }),

    //更新状态
    reqUpdataStatus: (data) => request({
        url: `/events/status`,
        method: 'PATCH',
        data
    }),

    //添加表单
    reqAddForm: (data) => request({
        url: `/events`,
        method: 'POST',
        data
    }),


    //修改表单
    reqUpdataForm: (data) => request({
        url: `/events`,
        method: 'PATCH',
        data
    }),

    //获取模板
    reqTemplate: () => request({
        url: `/events/template`,
        method: 'GET'
    }),




}