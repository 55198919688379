import { request } from "../request.js"

export default {
    // 获取助农业务发布右边列表
    reqArticlesList: ({ pageSize, index, channelCode,status,searchText }) => request({
        url: `/infoContents?pageSize=${pageSize}&index=${index}&channelCode=${channelCode}&status=${status}&keyWord=${searchText}`,
        method: 'GET',
    }),

    // 获取助农业务发布状态改变
    reqUpdataStatus: (data) => request({
        url: `/infoContents/status`,
        method: 'PATCH',
        data
    }),
}