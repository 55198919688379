const Layout = () => import("@/views/layout");
// const NoSide =  () => import("@/views/layout/NoSide");
// component为Layout的,如果需要配置面包屑,需要配置redirect

// meta信息解析:
//   id: 1,//该菜单的id,如果一个vue项目有不同的菜单，可以方便辨认并且显示 例如导航条的一级菜单名
//   idUrl://一开始进入一级菜单显示的页面path
//   tabsShow:true,//选项卡显示与否
//   breadcrumbShow:true,//面包屑显示与否
//   title: '我的申请',//菜单标题和面包屑标题和选项卡标题
//   icon: 'private-iconfont icon-ceshishenqing',//菜单icon
//   onlyMenuItem:true，//不会将该children里的路由作为菜单选项
//   activeMenu: '/my-apply/list-one', //，如果sidebar: false，就要填写高亮哪个菜单选项
//   sidebar: false, // 该路由不会显示在菜单中
//   breadcrumbTitle: '详情1' //面包屑标题

// 原始导航路由
const menuRoutes = [
  // 用户信息管理
  {
    meta: {
      id: "用户信息管理",
      // tabsShow:true,
      breadcrumbShow: true,
    },
    children: [
      {
        path: "/user",
        redirect: "user/farmerList",
        meta: {
          title: "用户信息管理",
          icon: "font_family icon-a-yonghuxinxiyonghu",
        },
        component: Layout,
        children: [
          {
            path: "farmerList",
            name: "userfarmerList",
            component: () => import("@/views/User/FarmerList/index.vue"),
            meta: {
              title: "农户信息",
              onlyMenuItem: true,
            },
          },
        ],
      },
    ],
  },
  // 农资商城管理
  {
    meta: {
      id: "农资商城管理",
      // tabsShow:true,
      breadcrumbShow: true,
    },
    children: [
      {
        path: "/mall",
        redirect: "mall/classify",
        meta: {
          title: "农资商城管理",
          icon: "font_family icon-mall",
        },
        component: Layout,
        children: [
          {
            path: "classify",
            name: "mallClassify",
            component: () => import("@/views/Mall/Classify/index.vue"),
            meta: {
              title: "农资商品分类",
              onlyMenuItem: true,
            },
          },
          {
            path: "msg",
            name: "mallMsg",
            component: () => import("@/views/Mall/Msg/index.vue"),
            meta: {
              title: "农资商品信息",
              onlyMenuItem: true,
            },
          },
          {
            path: "order",
            name: "mallOrder",
            component: () => import("@/views/Mall/Order/index.vue"),
            meta: {
              title: "农资订单",
              onlyMenuItem: true,
            },
          },
        ],
      },
    ],
  },
  // 农产品交易管理
  {
    meta: {
      id: "农产品交易管理",
      // tabsShow:true,
      breadcrumbShow: true,
    },
    children: [
      {
        path: "/product",
        redirect: "product/classify",
        meta: {
          title: "农产品交易管理",
          icon: "font_family icon-deal",
        },
        component: Layout,
        children: [
          {
            path: "classify",
            name: "productClassify",
            component: () => import("@/views/Product/Classify/index.vue"),
            meta: {
              title: "农产品商品分类",
              onlyMenuItem: true,
            },
          },
          {
            path: "msg",
            name: "productMsg",
            component: () => import("@/views/Product/Msg/index.vue"),
            meta: {
              title: "农产品商品信息",
              onlyMenuItem: true,
            },
          },
          {
            path: "audit",
            name: "productAudit",
            component: () => import("@/views/Product/Audit/index.vue"),
            meta: {
              title: "农产品发布审核",
              onlyMenuItem: true,
            },
          },
          {
            path: "order",
            name: "productOrder",
            component: () => import("@/views/Product/Order/index.vue"),
            meta: {
              title: "官方订单",
              onlyMenuItem: true,
            },
          },
          {
            path: "previewDetail",
            name: "PreviewDetail",
            component: () => import("@/views/Product/PreviewDetail/index.vue"),
            meta: {
              activeMenu: "/product/order",
              sidebar: false,
              title: "官方回购订单",
            },
          },
        ],
      },
    ],
  },
  // 助农业务管理
  {
    meta: {
      id: "助农业务管理",
      // tabsShow:true,
      breadcrumbShow: true,
    },
    children: [
      {
        path: "/business",
        redirect: "business/form",
        meta: {
          title: "助农业务管理",
          icon: "font_family icon-yewu",
        },
        component: Layout,
        children: [
          {
            path: "column",
            name: "Column",
            component: () => import("@/views/Business/Column/index.vue"),
            meta: {
              activeMenu: "/business/column",
              title: "栏目管理",
              onlyMenuItem: true,
            },
          },
          {
            path: "form",
            name: "businessForm",
            component: () => import("@/views/Business/Form/index.vue"),
            meta: {
              title: "助农业务表单",
              onlyMenuItem: true,
            },
          },

          {
            path: "release",
            name: "businessRelease",
            component: () => import("@/views/Business/Release/index.vue"),
            meta: {
              title: "助农业务发布",
              onlyMenuItem: true,
            },
          },
          {
            path: "releaseEdit",
            name: "businessReleaseEdit",
            component: () => import("@/views/Business/ReleaseEdit/index.vue"),
            meta: {
              activeMenu: "/business/release",
              sidebar: false,
              title: "助农业务发布",
            },
          },
          {
            path: "apply",
            name: "businessApply",
            component: () => import("@/views/Business/Apply/index.vue"),
            meta: {
              title: "助农业务申请",
              onlyMenuItem: true,
            },
          },

        ],
      },
    ],
  },
];
export { menuRoutes };
