<template>
  <div id="app">
    <Header v-if="$route.meta.hiddenHeader !== true" />
    <router-view></router-view>
  </div>
</template>

<script>
import Header from "@/components/Header.vue";

export default {
  name: "App",
  components: {
    Header,
  },
};

//http://192.168.0.224:9014/AASWebApi
</script>

<style lang="scss">
@import "@/assets/icon/public-icon/iconfont.css";
@import "@/assets/icon/risk-icon/iconfont.css";
</style>
