import { request } from "../request.js"

//农资商城管理分类
export default {

    // 获取商品列表
    reqMsgList: ({ pageSize = "", index = "", parentId = "", keyWord = "", categoryId = "", status = "" }) => request({
        url: `/skus?pageSize=${pageSize}&index=${index}&parentId=${parentId}&keyWord=${keyWord}&categoryId=${categoryId}&saleable=${status}`,
        method: 'GET',
    }),

    // 修改状态
    reqUpdateStatus: (data) => request({
        url: "/skus/saleable",
        method: 'PATCH',
        data
    }),

    // 添加
    reqAddMsg: (data) => request({
        url: "/skus",
        method: 'POST',
        data
    }),

    // 修改
    reqUpdataMsg: (data) => request({
        url: "/skus",
        method: 'PATCH',
        data
    }),

}
