import { businessColumn } from "@/api/index"
import treeData from '@/utils/treeData'

const actions = {
    //获取栏目管理左边目录
    async getDirectoryData(context, status) {
        let res = await businessColumn.reqChannelsDirectory({
            status: status
        })
        let code = ""
        if (res.Data) {
            context.state.directoryData = res.Data
            let index = res.Data.findIndex((item) => {
                return !item.ParentCode
            })
            return res.Data[index].ChannelCode
        }else{
            return "123"
        }

    },
    //获取栏目管理右边目录
    async getChannelsList(context, obj) {
        let res = await businessColumn.reqChannelsList(obj)
        if (res.Data) {
            res.Data.forEach((item, index) => {
                res.Data[index].check = false
            })
        }
        context.state.total = res.TotalItemCount
        context.state.channelsList = res.Data
    },

}

const mutations = {}


const state = {
    directoryData: [],
    channelsList: [],
    total: 0,
}

const getters = {
    formDirectoryData(state) {
        let data = []
        state.directoryData.forEach(item => {
            data.push({ ...item, children: [] })
        })
        return data
    },

    treeDirectoryData(state) {
        const datalist = state.directoryData.filter((item) => {
            return item.ChannelCode !== "AAS_C_lm21"
        })

        let data = treeData(datalist)


        return [
            [
                {
                    ChannelName: "助农栏目",
                    ChannelCode: "123",
                    children: data[1]
                }
            ],

            data[0]
        ]
    },
}
export default {
    namespaced: true,
    state,
    mutations,
    actions,
    getters
}

