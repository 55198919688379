/**
 * @param datalist 需要转变为树形结构的数据列
 * @return [0: 返回添加children的datalist， 1: 树形结构数据]
 */
export default function nestRoutes(datalist) {
    // 添加空的children
    for (const datalistElement of datalist) {
        if (!datalistElement['children']) {
            datalistElement['children'] = []
        }
    }


    // 遍历datalist，添加子节点
    for (const datalistElement of datalist) {
        addChildrenNode(datalistElement)
    }

    function addChildrenNode(dataNode) {
        if (dataNode['ParentCode']) {
            let tempNode = null

            // 寻找该节点的父节点，并获取它
            for (let i = 0; i < datalist.length; i++) {
                if (datalist[i]['ChannelCode'] === dataNode['ParentCode']) {
                    tempNode = datalist[i]
                }
            }

            if (tempNode) {
                // 判单该节点的子节点中是否已经存在该节点，如果存在，则不添加该节点
                let isPush = tempNode['children'].some((item) => {
                    return item['ChannelCode'] === dataNode['ChannelCode']
                })
                if (!isPush) {
                    tempNode['children'].push(dataNode)
                }
                // 递归
                addChildrenNode(tempNode)
            }
        }
    }

    const formData = [];


    datalist.forEach(item => {
        // 二级目录的情况
        if (!item.ParentCode || item.ParentCode === '') {
            formData.push(item);
        }
    });

    return [datalist, formData];
}
