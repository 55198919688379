import axios from "axios";
import { getCookie } from "@/utils/public2";
import { Message } from 'element-ui';

const contentTypes = {
  json: "application/json; charset=utf-8",
  urlencoded: "application/x-www-form-urlencoded; charset=utf-8",
  multipart: "multipart/form-data",
};


const defaultOptions = {
  withCredentials: false, // 允许把cookie传递到后台
  headers: {
    Accept: "application/json",
    "Content-Type": contentTypes.json,
  },
  timeout: 15000,
};

//
let TestUrl = process.env.VUE_APP_Server;

// if (process.env.NODE_ENV === "development") {
//   TestUrl = "http://192.168.0.226:9601";
// } else {
//   TestUrl = "http://10.249.202.71:9001";
// }

export const request = ({
  url,
  data = {},
  params,
  method = "get",
  options = {},
  contentType = "json", // json || urlencoded || multipart
  prefixUrl = TestUrl,
}) => {
    if (!url) {
      const error = new Error("请传入url");
      return Promise.reject(error);
    }
    let fullUrl = `/${prefixUrl}${url}`;

    if (options.fullUrl) {
      fullUrl = options.fullUrl;
    }

    const newOptions = {
      ...defaultOptions,
      ...options,
      params: {
        ...params,
        returnUrl: location.href
      },
      headers: {
        "Content-Type":
          (options.headers && options.headers["Content-Type"]) ||
          contentTypes[contentType],
          SSOToken: getCookie("ssotoken"),
      },
      method,
    };

    if (method === "get") {
      newOptions.params = data;
      newOptions.params.returnUrl = location.href
    }

    if (method !== "get" && method !== "head") {
      if (options.forceParams) {
        newOptions.params = data;
      } else {
        newOptions.data = data;
        if (data instanceof FormData) {
          newOptions.headers = {
            "x-requested-with": "XMLHttpRequest",
            "cache-control": "no-cache",
          };
        } else {
          Object.keys(data).forEach((item) => {
            if (data[item] === null || data[item] === undefined) {
              delete data[item];
            }
          });
        }
      }
    }

    axios.interceptors.request.use((request) => {
      // 移除起始部分 / 所有请求url走相对路径
      request.url = request.url.replace(/^\//, "");
      return request;
    });

    return axios({
      url: fullUrl,
      ...newOptions,
    })
      .then((response) => {
        const { data } = response;

        if (response.status !== 200) {
          return Promise.reject(data);
        } else {
          return Promise.resolve(response.data);
        }
      })
      .catch((error) => {
        if (error.response) {
          const { data } = error.response;
          const resCode = data.ErrorCode;
          const resMsg = data.ErrorMessage || "服务异常";
          if (resCode === 301) {
            // 登录校验失败
            location.href = data.Url;
          } else if (resCode === 403) {
            // 与服务端约定
            // 无权限
            Message.error("403")
          } else if (resCode === 401) {
            // 与服务端约定
            // 无权限
            Message.error("401")
          }
          const err = { code: resCode, respMsg: resMsg };
          Message.error("error")
        } else {
          const err = { type: "canceled", respMsg: "数据请求超时" };
          Message.error("error")
        }
      });



};

