<template>
  <!--头部 开始-->
  <div style="width: 100vw;">
    <!--头部导航条 开始-->
    <div class="nav-header">
      <div class="nav-logo">
      </div>
      <div class="nav-box">
        <div class="nav-right">
          <div class="nav-info">
            <div class="nav-info-mine">
              <div class="nav-user-avatar">
                <!-- <i class="public-icon icon-wode2 nav-user-icon"></i> -->
                <img src="@/assets/images/user-logo.png" />
              </div>
              <!-- <div class="nav-user-img">
                <i class="public-icon icon-wode2 nav-user-icon"></i>
              </div> -->
              张三
              <i class="public-icon icon-sanjiaoxing_shang-copy nav-arrow-icon"
                style="display: inline-block; transform: rotate(180deg)"></i>
            </div>
            <div class="nav-info-list">
              <a class="list-item">
                <i class="list-item-icon public-icon icon-hetong"></i>
                <span class="list-item-title">切换账号</span>
              </a>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <!--头部 结束-->
</template>

<script>
export default {
  name: "Header",
  data () {
    return {};
  },

  methods: {
    handleActive (name) {
      name && this.$router.push({ name: name });
    },
  },
};
</script>

<style lang="scss" scoped>
  .nav-header{
    width: 100vw !important;
    min-width: none;
  }
.nav-box{
  display: flex;
  justify-content: flex-end !important;

}
</style>
