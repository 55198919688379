import Vue from 'vue'
import Vuex from 'vuex'
import menu from './modules/menu'
import businessColumn from './modules/businessColumn'
import businessRelease from './modules/businessRelease'

Vue.use(Vuex)

const store = new Vuex.Store({
  modules:{
    menu,
    businessColumn,
    businessRelease
  },
})
export default store