import { request } from "../request.js"

//农资商城管理分类
export default {

    // 获取商品列表
    reqOrderList: ({ pageSize = "", index = "", keyWord = "", categoryId = "", status = "" }) => request({
        url: `/orderInfo?pageSize=${pageSize}&index=${index}&keyWord=${keyWord}&categoryId=${categoryId}&status=${status}`,
        method: 'GET',
    }),

    // 修改状态
    reqUpdataStatus: (data) => request({
        url: `/orderInfo/status`,
        method: 'PATCH',
        data
    }),

    // 修改订单
    reqUpdataInfo: (data) => request({
        url: `/orderInfo`,
        method: 'PATCH',
        data
    }),


}