import { request } from "../request.js"

//农资商城管理分类
export default {
    // 获取分类列表
    reqAuditList: ({ pageSize, index, PublishStatus, categoryId, keyWord }) => request({
        url: `/trade?pageSize=${pageSize}&index=${index}&PublishStatus=${PublishStatus}&categoryId=${categoryId}&keyWord=${keyWord}`,
        method: 'GET',
    }),
    // 获取分类列表
    reqCategoryList: ({ pageSize = "", index = "", parentId = "", keyWord = "", IsPaged = true }) => request({
        url: `/categories?pageSize=${pageSize}&index=${index}&parentId=${parentId}&keyWord=${keyWord}&IsPaged=${IsPaged}`,
        method: 'GET',
    }),
    // 更新审核状态
    reqUpdatePublishStatus: (data) => request({
        url: `/trade/publishStatus`,
        method: 'PATCH',
        data
    }),

}
