import { request } from "../request.js"

//发布编辑
export default {

    //上传文件
    reqUploadDbFile: (data) => request({
        url: `/Common/UploadDbFile`,
        method: 'POST',
        data
    }),

    //删除文件
    reqDeleteDbFile: (fileId) => request({
        url: `/Common/DeleteDbFile?fileId=${fileId}`,
        method: 'GET',
    }),

    //提交文章
    reqInfoContents: (data) => request({
        url: `/infoContents`,
        method: 'POST',
        data
    }),
    
    // 获取助农业务发布右边列表
    reqArticlesList: (id) => request({
        url: `/infoContents/${id}`,
        method: 'GET',
    }),

    //修改文章
    reqUpdataInfoContents: (data) => request({
        url: `/infoContents`,
        method: 'PATCH',
        data
    }),


}